<template>
    <div class="toast show" :class="[toast.backgroundClass, toast.textClass]" role="alert">
        <div class="toast-header" v-if="toast.title" :class="[toast.backgroundClass, toast.textClass]">
            <strong class="me-auto">{{ toast.title }}</strong>
            <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close" @click="toasts.removeToast(toast)"></button>
        </div>
        <div class="toast-body">
            {{ toast.text }}
        </div>
    </div>
</template>

<script setup>

import {Toast, useToastStore} from "@/store/toast";

const toasts = useToastStore();

defineProps({
    toast: Toast,
})
</script>
