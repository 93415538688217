import {defineStore} from "pinia";
import {Ref, ref} from "vue";
import {randomString} from "@/helpers";

class Toast {
    private readonly _id: string;
    private readonly _title: string;
    private readonly _text: string;
    private readonly _backgroundClass: string;
    private readonly _textClass: string;

    constructor(id: string | null = null, title: string, text: string, backgroundClass: string = 'bg-primary') {
        this._id = id || randomString();
        this._title = title;
        this._text = text;
        this._backgroundClass = backgroundClass;
        this._textClass = backgroundClass === 'bg-primary' ? 'text-white' : 'text-black';
    }

    get id() {
        return this._id;
    }

    get title() {
        return this._title;
    }

    get text(): string {
        return this._text;
    }

    get backgroundClass(): string {
        return this._backgroundClass;
    }

    get textClass(): string {
        return this._textClass;
    }
}

const useToastStore = defineStore('toast', function () {
    const toasts: Ref<Record<string, Toast>> = ref({} as Record<string, Toast>);

    function addToast(toast: Toast) {
        toasts.value[toast.id] = toast;
    }

    function removeToast(toast: Toast) {
        delete toasts.value[toast.id];
    }

    function addTemporaryToast(toast: Toast, ttl: number = 5000) {
        addToast(toast);

        setTimeout(() => {
            removeToast(toast)
        }, ttl)
    }

    function getToasts(): Array<Toast> {
        return Object.values(toasts.value);
    }

    function error(text: string) {
        addTemporaryToast(new Toast('api-error', 'Виникла помилка', text, 'bg-danger'))
    }

    return {
        toasts,
        addToast,
        removeToast,
        addTemporaryToast,
        getToasts,
        error,
    }
});

export {useToastStore, Toast}
