import {NavigationGuardNext, RouteLocationNormalized, useRouter} from "vue-router";
import {useAuthStore} from "@/store/auth";
import {Toast, useToastStore} from "@/store/toast";

export function checkAuthenticationMiddleware(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
) {
    const auth = useAuthStore();
    const toast = useToastStore();

    const router = useRouter();

    const forbiddenToast = () => {
        toast.addTemporaryToast(
            new Toast('auth-error', 'Заборонена дія', 'Доступ до цієї сторінки заборонено неавторизованим користувачам', 'bg-danger')
        )
    }

    if (!to.fullPath.startsWith('/my')) {
        return next();
    }

    if (auth.isInitialized && (!auth.user || auth.user.status !== 'verified')) {
        forbiddenToast();

        return next('/')
    }

    if (!auth.isInitialized) {
        auth
            .fetchUser()
            .then(user => {
                if (!user || user.status !== 'verified') {
                    forbiddenToast();
                    router.push('/')
                }
            })
    }

    return next();
}
