import {defineStore} from "pinia";
import {ref} from "vue";
import {SearchResponse, Supplier} from "@/services/api/entities";
import {Toast, useToastStore} from "@/store/toast";

const usePartStore = defineStore('part', function () {
    const cache = ref({} as Record<string, SearchResponse>);
    const suppliersCache = ref([] as Array<Supplier>);

    const toasts = useToastStore();

    async function search(code: string): Promise<SearchResponse | null> {
        if (cache.value[code]) {
            return cache.value[code];
        } else {
            try {
                // @ts-ignore
                const response = await this.api.search(code)
                console.log(response);
                if (response) {
                    cache.value[response.part.code] = response;
                    return cache.value[response.part.code];
                }
            } catch (e) {
                if (e.response && e.response.status === 404) {
                    toasts.addTemporaryToast(new Toast(
                        null,
                        'Запчастина не знайдена',
                        `За вашим запитом ${code} не знайдено жодної запчастини. Перевірте введений код і спробуйте ще раз`,
                        'bg-secondary'
                    ))
                    return null;
                } else {
                    toasts.error('На жаль, сталася помилка під час обробки вашого запиту. Будь ласка, перезавантажте сторінку та спробуйте ще раз. Якщо проблема зберігається, зверніться до підтримки.')
                }
            }

            return null;
        }
    }

    function clear(): void {
        cache.value = {};
    }

    async function getSuppliers() {
        // @ts-ignore
        suppliersCache.value = await this.api.getSuppliers();
    }

    return {
        cache,
        suppliersCache,

        search,
        clear,
        getSuppliers,
    }
});

export {usePartStore}
