export type InterpretationStatus =
    'in_production'
    | 'has_replacement'
    | 'has_multidimensional_replacement'
    | 'discontinued'

export type UserStatus =
    'verified'
    | 'rejected'
    | 'undefined';

export type WarehouseUploadStatus =
    'waiting'
    | 'processing'
    | 'error'
    | 'processed'
    | 'processed_with_errors';

export type WarehouseUploadErrorType =
    'notFound'
    | 'unknown';

export class Part {
    private readonly _uuid: string;
    private readonly _title: string;
    private readonly _code: string;
    private readonly _interpretationStatus: InterpretationStatus;

    constructor(uuid: string, title: string, code: string, interpretationStatus: InterpretationStatus) {
        this._uuid = uuid;
        this._title = title;
        this._code = code;
        this._interpretationStatus = interpretationStatus;
    }

    get uuid(): string {
        return this._uuid;
    }

    get title(): string {
        return this._title;
    }

    get code(): string {
        return this._code;
    }

    get interpretationStatus(): InterpretationStatus {
        return this._interpretationStatus;
    }

    static fromJSON(data: any) {
        return new Part(
            data.uuid,
            data.title,
            data.code,
            data.interpretationStatus
        );
    }
}

export class ImporterInfo {
    // private readonly _price: bigint;
    private readonly _retailPrice: bigint;

    constructor(retailPrice: bigint) {
        this._retailPrice = retailPrice;
    }

    get retailPrice(): bigint {
        return this._retailPrice;
    }
}

export class WarehousePart {
    private readonly _uuid: string;
    private readonly _quantity: bigint;
    private readonly _price: bigint | null;

    private readonly _costsLessThanImporter: boolean;

    private readonly _supplier: Supplier;

    constructor(uuid: string, quantity: bigint, price: bigint | null, costsLessThanImporter: boolean, supplier: Supplier) {
        this._uuid = uuid;
        this._quantity = quantity;
        this._price = price;
        this._costsLessThanImporter = costsLessThanImporter;

        this._supplier = supplier;
    }

    get uuid(): string {
        return this._uuid;
    }

    get quantity(): bigint {
        return this._quantity;
    }

    get price(): bigint | null {
        return this._price;
    }

    get costsLessThanImporter(): boolean {
        return this._costsLessThanImporter;
    }

    get supplier(): Supplier {
        return this._supplier;
    }
}

export class WarehouseUpload {
    private readonly _uuid: string;
    private readonly _total: number;
    private readonly _processed: number;
    private readonly _status: WarehouseUploadStatus;
    private readonly _errors: Array<WarehouseUploadError>;
    private readonly _validityDate: Date;
    private readonly _createdAt: Date;

    constructor(
        uuid: string,
        total: number,
        processed: number,
        status: WarehouseUploadStatus,
        errors: Array<WarehouseUploadError>,
        validityDate: Date,
        createdAt: Date
    ) {
        this._uuid = uuid;
        this._total = total;
        this._processed = processed;
        this._status = status;
        this._errors = errors;
        this._validityDate = validityDate;
        this._createdAt = createdAt;
    }

    get uuid(): string {
        return this._uuid;
    }

    get total(): number {
        return this._total;
    }

    get processed(): number {
        return this._processed;
    }

    get status(): WarehouseUploadStatus {
        return this._status;
    }

    get errors(): Array<WarehouseUploadError> {
        return this._errors;
    }

    get validityDate(): Date {
        return this._validityDate;
    }

    get createdAt(): Date {
        return this._createdAt;
    }

    public static fromJSON(data: any) {
        return new WarehouseUpload(
            data.uuid,
            data.total,
            data.processed,
            data.status,
            data.errors.map((error: any) => WarehouseUploadError.fromJson(error)),
            new Date(data.validityDate),
            new Date(data.createdAt),
        )
    }
}

export class WarehouseUploadError {
    constructor(
        private readonly _line: number,
        private readonly _partCode: string,
        private readonly _error: WarehouseUploadErrorType,
    ) {
    }

    get line(): number {
        return this._line;
    }

    get partCode(): string {
        return this._partCode;
    }

    get error(): WarehouseUploadErrorType {
        return this._error;
    }

    public static fromJson(data: any) {
        return new WarehouseUploadError(
            data.line,
            data.partCode,
            data.error,
        );
    }
}

export class NewPart {
    private readonly _code: string;
    private readonly _quantity: bigint;
    private readonly _price: bigint;

    constructor(code: string, quantity: bigint, price: bigint) {
        this._code = code;
        this._quantity = quantity;
        this._price = price;
    }

    get code(): string {
        return this._code;
    }

    get quantity(): bigint {
        return this._quantity;
    }

    get price(): bigint {
        return this._price;
    }

    public toJSON() {
        return {
            code: this._code,
            quantity: this._quantity,
            price: this._price,
        }
    }
}

export class RemainsWarehousePart {
    private readonly _uuid: string;
    private readonly _quantity: bigint;
    private readonly _price: bigint;
    private readonly _part: Part;

    constructor(uuid: string, quantity: bigint, price: bigint, part: Part) {
        this._uuid = uuid;
        this._quantity = quantity;
        this._price = price;
        this._part = part;
    }

    get uuid(): string {
        return this._uuid;
    }

    get quantity(): bigint {
        return this._quantity;
    }

    get price(): bigint {
        return this._price;
    }

    get part(): Part {
        return this._part;
    }

    static fromJSON(data: any) {
        return new RemainsWarehousePart(
            data.uuid,
            data.quantity,
            data.price,
            Part.fromJSON(data.part),
        );
    }
}

export class Supplier {
    private readonly _uuid: string;
    private readonly _title: string;
    private readonly _city: string;
    private readonly _address: string;
    private readonly _phones: string | null;
    private readonly _isDealership: boolean;
    private readonly _googleMapLink: string | null;
    private readonly _validityDate: Date;

    constructor(uuid: string, title: string, city: string, address: string, phones: string | null, isDealership: boolean, googleMapLink: string | null, validityDate: Date) {
        this._uuid = uuid;
        this._title = title;
        this._city = city;
        this._address = address;
        this._phones = phones;
        this._isDealership = isDealership;
        this._googleMapLink = googleMapLink;
        this._validityDate = validityDate;
    }

    get uuid(): string {
        return this._uuid;
    }

    get title(): string {
        return this._title;
    }

    get city(): string {
        return this._city;
    }

    get address(): string {
        return this._address;
    }

    get phones(): string | null {
        return this._phones;
    }

    get isDealership(): boolean {
        return this._isDealership;
    }

    get googleMapLink(): string | null {
        return this._googleMapLink;
    }

    get validityDate(): Date {
        return this._validityDate;
    }

    toJSON() {
        return {
            title: this._title,
            city: this._city,
            address: this._address,
            phones: this._phones,
            googleMapLink: this._googleMapLink,
            validityDate: this._validityDate
        }
    }

    static fromJSON(data: any) {
        return new Supplier(
            data.uuid,
            data.title,
            data.city,
            data.address,
            data.phones,
            data.isDealership,
            data.googleMapLink,
            new Date(data.validityDate),
        );
    }
}

export class Interpretation {
    private readonly _from: string;
    private readonly _to: string;

    constructor(from: string, to: string) {
        this._from = from;
        this._to = to;
    }

    get from(): string {
        return this._from;
    }

    get to(): string {
        return this._to;
    }
}

export class InterpretationRemains extends Part {
    private readonly _remains: number;

    constructor(
        uuid: string,
        code: string,
        title: string,
        status: InterpretationStatus,
        remains: number
    ) {
        super(uuid, title, code, status);

        this._remains = remains;
    }

    get remains() {
        return this._remains;
    }
}

export class SearchResponse {
    private readonly _part: Part;
    private readonly _importerInfo: ImporterInfo | null;
    private readonly _warehouseParts: Array<WarehousePart>;
    private readonly _interpretationRemains: Array<InterpretationRemains>;

    constructor(part: Part, importerInfo: ImporterInfo | null, warehouseParts: Array<WarehousePart>, interpretationRemains: Array<InterpretationRemains>) {
        this._part = part;
        this._importerInfo = importerInfo;
        this._warehouseParts = warehouseParts;
        this._interpretationRemains = interpretationRemains;
    }

    get part(): Part {
        return this._part;
    }

    get importerInfo(): ImporterInfo | null {
        return this._importerInfo;
    }

    get warehouseParts(): Array<WarehousePart> {
        return this._warehouseParts;
    }

    get interpretationRemains(): Array<InterpretationRemains> {
        return this._interpretationRemains;
    }
}

export class User {
    private readonly _uuid: string;
    private readonly _name: string;
    private readonly _email: string;
    private readonly _status: UserStatus;
    private readonly _supplier: Supplier | null;

    constructor(uuid: string, name: string, email: string, status: UserStatus, supplier: Supplier | null) {
        this._uuid = uuid;
        this._name = name;
        this._email = email;
        this._status = status;
        this._supplier = supplier;
    }

    get uuid(): string {
        return this._uuid;
    }

    get name(): string {
        return this._name;
    }

    get email(): string {
        return this._email;
    }

    get status(): UserStatus {
        return this._status;
    }

    get supplier(): Supplier | null {
        return this._supplier;
    }

    toJSON() {
        return {
            uuid: this._uuid,
            name: this._name,
            supplier: this._supplier,
        }
    }

    static fromJSON(data: any): User {
        return new User(
            data.uuid,
            data.name,
            data.email,
            data.status,
            data.supplier
                ? Supplier.fromJSON(data.supplier)
                : null,
        );
    }
}

export class Service {
    constructor(
        private _uuid: string,
        private _title: string,
        private _description: string,
        private _price: number,
        private _subscriptions: Array<Subscription> = [],
    ) {
    }

    get uuid(): string {
        return this._uuid;
    }

    get title(): string {
        return this._title;
    }

    get description(): string {
        return this._description;
    }

    get price(): number {
        return this._price;
    }

    get subscriptions(): Array<Subscription> {
        return this._subscriptions;
    }

    static fromJSON(data: any): Service {
        return new Service(
            data.uuid,
            data.title,
            data.description,
            data.price,
            data.subscriptions ? data.subscriptions.map((subscription: any) => Subscription.fromJSON(subscription)) : [],
        )
    }
}

export class Subscription {
    constructor(
        private _uuid: string,
        private _amount: number,
        private _dateStart: Date,
        private _dateEnd: Date,
        private _createdAt: Date,
        private _service: Service,
    ) {
    }

    get uuid(): string {
        return this._uuid;
    }

    get amount(): number {
        return this._amount;
    }

    get dateStart(): Date {
        return this._dateStart;
    }

    get dateEnd(): Date {
        return this._dateEnd;
    }

    get createdAt(): Date {
        return this._createdAt;
    }

    get service(): Service {
        return this._service;
    }

    static fromJSON(data: any): Subscription {
        return new Subscription(
            data.uuid,
            data.amount,
            new Date(data.dateStart),
            new Date(data.dateEnd),
            new Date(data.createdAt),
            Service.fromJSON(data.service)
        )
    }
}