import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import {changeTitleMiddleware} from "@/router/middleware/title";
import {checkAuthenticationMiddleware} from "@/router/middleware/authorized";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Головна',
        component: () => import(/* webpackChunkName: "search" */ '../pages/SearchPage.vue'),
        meta: {
            title: 'Головна'
        }
    },
    {
        path: '/my/supplier',
        name: 'Користувачі',
        component: () => import(/* webpackChunkName: "my_supplier" */ '../pages/my/SupplierPage.vue'),
        meta: {
            title: 'Постачальник'
        },
    },
    {
        path: '/my/warehouse',
        name: 'Склад',
        component: () => import(/* webpackChunkName: "my_warehouse" */ '../pages/my/WarehousePage.vue'),
        meta: {
            title: 'Склад'
        },
    },
    {
        path: '/my/warehouse/upload',
        name: 'Завантаження складу',
        component: () => import(/* webpackChunkName: "my_warehouse_upload" */ '../pages/my/WarehouseUploadPage.vue'),
        meta: {
            title: 'Завантаження складу'
        },
    },
    {
        path: '/my/warehouse/upload/status',
        name: 'Статус завантаження складу',
        component: () => import(/* webpackChunkName: "my_warehouse_upload_status" */ '../pages/my/WarehouseUploadStatusPage.vue'),
        meta: {
            title: 'Статус завантаження складу'
        },
    },
    {
        path: '/my/subscription',
        name: 'Послуги',
        component: () => import(/* webpackChunkName: "my_subscription" */ '../pages/my/Subscription.vue'),
        meta: {
            title: 'Послуги'
        },
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(changeTitleMiddleware)
router.beforeEach(checkAuthenticationMiddleware)

export default router
