const numberFormat = (number: number, decimals: number = 2, oct: number = 3): string => {
    const octPower = Math.pow(10, oct);

    // fraction
    const fraction = number % (Math.pow(10, decimals));
    const fractionString = String(fraction).padStart(decimals, '0')

    // whole
    let whole = Math.floor(number / (Math.pow(10, decimals)));
    whole = parseInt(whole.toFixed())

    let result = [];

    while ((whole % octPower) > 0) {
        let decade = whole % octPower;
        let mediate = whole;
        whole = parseInt(Math.floor((whole / octPower)).toFixed());

        // if not last, pad oct
        result.unshift(
            whole
                ? String(decade).padStart(oct, '0')
                : mediate
        )
    }

    return (result.length ? result.join(' ') : '0') + '.' + fractionString;
};

export const uah = (price: number): string => {
    return numberFormat(price) + " ₴";
}

export const getNoun = (number: number, one: string, two: string, five: string): string => {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
        return five;
    }
    n %= 10;
    if (n === 1) {
        return one;
    }
    if (n >= 2 && n <= 4) {
        return two;
    }
    return five;
};

export const humanizedDiff = (date: Date) => {
    const diffInDays = ((new Date()).getTime() - date.getTime()) / 1000 / 86400;

    if (diffInDays < 1) {
        return "сьогодні";
    } else {
        return Number(diffInDays).toFixed() + " " + getNoun(Math.round(diffInDays), "день", "дні", "днів");
    }
};

export const moreThanTwoMonth = (date: Date) => (((new Date()).getTime() - date.getTime()) / 1000 / 86400) > 2 * 30;

export const randomString = (length: number = 10): string => {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        result += charset[randomIndex];
    }

    return result;
}

export const guessCSVDelimiter = (csvContent: string): string => {
    const potentialDelimiters = [';', '\t', '|', ':', ' ', ','];

    for (const delimiter in potentialDelimiters) {
        if (csvContent.indexOf(delimiter) !== -1) {
            return ';'
        }
    }

    return ',';
}

export const guessColTypes = (records: Array<Array<string>>) => {
    let result = [null, null, null] as Array<string | null>;

    const sumByIndex = [-1, -1, -1];

    // Цикл для перевірки кожної колонки
    for (let i = 0; i < records[0].length; i++) {
        // Якщо колонка є "запчастинами" і містить хоча б одну літеру
        if (records.some(row => /[a-z]/i.test(row[i]))) {
            result[i] = 'code';
            continue;
        }

        sumByIndex[i] = records.reduce((sum, row) => sum + parseFloat(row[i]), 0);
    }

    const priceIndex = sumByIndex.indexOf(Math.max(...sumByIndex));
    result[priceIndex] = 'price';
    sumByIndex[priceIndex] = -1;

    const quantityIndex = sumByIndex.indexOf(Math.max(...sumByIndex));
    result[quantityIndex] = 'quantity';

    return result;
}

export const formatDate = (date: Date): string => {
    return date.toLocaleString('uk-ua', {day: '2-digit', month: 'long', year: 'numeric'});
}

export const formatTime = (date: Date): string => {
    return date.toLocaleString('uk-ua', {hour: 'numeric', minute: 'numeric'});
}

export const interpretationStatusMap = {
    'in_production': 'Виробляється',
    'has_replacement': 'Має заміну',
    'has_multidimensional_replacement': 'Має багатовимірну заміну',
    'discontinued': 'Знято з виробництва',
};
