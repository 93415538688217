import {nextTick} from 'vue';
import {NavigationGuardNext, RouteLocationNormalized} from "vue-router";

const suffix = 'Фольксваген Партс Каталог';

function changeTitleMiddleware(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    nextTick(() => {
        document.title = to.meta.title
            ? `${to.meta.title} | ${suffix}`
            : suffix;
    });

    next();
}

export {changeTitleMiddleware}
