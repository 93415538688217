<template>
    <div class="position-relative">
        <div class="toast-container top-0 end-0 p-3">
            <toast :toast="toast" v-for="toast in toastStore.getToasts()"/>
        </div>
    </div>
</template>

<script setup>
import {useToastStore} from "@/store/toast";
import Toast from "@/components/common/toast.vue";

const toastStore = useToastStore();
</script>
