import axios, {Axios, AxiosResponse} from "axios";
import {
    ImporterInfo,
    Interpretation,
    Part,
    InterpretationRemains,
    SearchResponse,
    Supplier, User,
    WarehousePart, RemainsWarehousePart, NewPart, WarehouseUpload, Service, Subscription
} from "@/services/api/entities";

export class ServerError extends Error {

}

export class Api {
    private client: Axios;

    constructor(url: string) {
        this.client = axios.create({
            baseURL: url,
            timeout: 30000,
        })
    }

    public search(code: string): Promise<SearchResponse> {
        return this
            .send('GET', '/v1/warehouse:search', {code})
            .then((response) => {
                const data = response.data;

                if (typeof data === 'string') {
                    throw new ServerError();
                }

                return new SearchResponse(
                    Part.fromJSON(data.part),
                    data.importerInfo
                        ? new ImporterInfo(
                            data.importerInfo.retailPrice
                        )
                        : null,
                    data.warehouse.map((warehousePart: any) => {
                        return new WarehousePart(
                            warehousePart.uuid,
                            warehousePart.quantity,
                            warehousePart.price ?? null,
                            warehousePart.lessThenImporter,
                            Supplier.fromJSON(warehousePart.supplier),
                        );
                    }),
                    data.interpretations.map((partRemains: any) => new InterpretationRemains(
                        partRemains.uuid,
                        partRemains.code,
                        partRemains.title,
                        partRemains.status,
                        partRemains.remains,
                    ))
                );
            })
            ;
    }

    public async getMyWarehouseRemains(): Promise<Array<RemainsWarehousePart>> {
        const response = await this.send('GET', '/v1/warehouse:my');

        return response
            .data
            .remains
            .map((remains: any) => RemainsWarehousePart.fromJSON(remains))
    }

    public async uploadWarehouse(parts: Array<NewPart>) {
        return await this.send('POST', '/v1/warehouse:upload', null, {
            parts
        })
    }

    public async fetchWarehouseUploads():Promise<Array<WarehouseUpload>> {
        const response = await this.send('GET', '/v1/warehouse:upload')
        return response.data.uploads.map((item: any) => WarehouseUpload.fromJSON(item));
    }

    public getUser(): Promise<User | null> {
        return new Promise(
            (resolve, reject) => this
                .send('GET', '/v1/user')
                .then((response) => resolve(User.fromJSON(response.data)))
                .catch((response) => {
                    if (response.response.status === 401) {
                        resolve(null)
                    } else {
                        reject(response)
                    }
                })
        );
    }

    public async getSuppliers(): Promise<Array<Supplier>> {
        const response = await this.send('GET', '/v1/suppliers');

        return response
            .data
            .suppliers
            .map((supplier: any) => Supplier.fromJSON(supplier));
    }

    public async getSupplierUsers(): Promise<Array<User>> {
        const response = await this.send('GET', '/v1/supplier/users')

        return response
            .data
            .users
            .map((user: any) => User.fromJSON(user));
    }

    public async getServices():Promise<Array<Service>> {
        const response = await this.send('GET', '/v1/services');

        return response
            .data
            .services
            .map((service: any) => Service.fromJSON(service))
    }

    public async getMySubscriptions():Promise<Array<Subscription>> {
        const response = await this.send('GET', '/v1/services/subscriptions');

        return response
            .data
            .subscriptions
            .map((service: any) => Subscription.fromJSON(service))
    }

    public async logout(): Promise<void> {
        await this.send('POST', '/v1/logout');
    }

    async setUserSupplier(supplierUuid: string) {
        await this.send('PUT', `/v1/user/supplier/${supplierUuid}/`);
    }

    private send(method: 'GET' | 'POST' | 'PUT' | 'DELETE', url: string, params: Object | null = null, data: Object | null = null): Promise<AxiosResponse> {
        return this
            .client
            .request({
                method,
                url,
                params,
                data,
            });
    }
}
