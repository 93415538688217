<script setup>
import {onMounted, onUnmounted} from "vue";

const emit = defineEmits(['close']);

const props = defineProps({
    show: Boolean,
    size: String,
    isCloseable: {
        type: Boolean,
        default: true,
    }
})

/**
 * @param {KeyboardEvent} e
 */
const onEscape = (e) => {
    if (props.isCloseable && e.code === 'Escape') {
        emit('close')
    }
}

onMounted(() => window.addEventListener('keydown', onEscape));
onUnmounted(() => window.removeEventListener('keydown', onEscape));
</script>

<template>
    <teleport to="body">
        <transition name="modal">
            <div class="modal-backdrop fade show" v-if="props.show"/>
        </transition>

        <transition name="modal">
            <div class="modal fade show"
                 :class="{'modal-lg': props.size === 'lg', 'modal-sm': props.size === 'sm'}"
                 tabindex="-1"
                 style="display: block;"
                 v-if="props.show"
                 @click.self="props.isCloseable && emit('close')"
            >
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5">
                                <slot name="title" />
                            </h1>
                            <button type="button" class="btn-close" @click="emit('close')" v-if="props.isCloseable"></button>
                        </div>
                        <div class="modal-body">
                            <slot />
                        </div>
                        <slot name="footer"></slot>
                    </div>
                </div>
            </div>
        </transition>
    </teleport>
</template>

<style>
.modal-enter-active,
.modal-leave-active {
    transition: opacity .15s linear;
}

.modal-enter-from,
.modal-leave-to {
    opacity: 0;
}
</style>