<template>
    <footer class="navbar navbar-expand-md navbar-dark bg-black text-white mt-auto py-3">
        <div class="container d-block">
            <p class="my-2">
                Ресурс "Фольксваген Партс Каталог UA" не має відношення до офіційного Volkswagen AG і його представників
                в Україні.
            </p>

            <p class="my-2">
                Всю інформацію, а саме ціну, кількість, актуальність і т.д., яку представлено на сайті, було отримано із
                відкритих джерел, вона не є публічною офертою жодного із згаданих дилерів Volkswagen в Україні і служить
                лише для внутрішнього використання дилерами Volksvagen в Україні.
            </p>

            <p class="my-2">
                <a class="text-decoration-none text-info" target="_blank" href="/terms" >Умови Використання</a>
                |
                <a class="text-decoration-none text-info" target="_blank" href="/services">Послуги</a>
                |
                <a class="text-decoration-none text-info" target="_blank" href="/privacy">Політика конфіденційності</a>
            </p>
        </div>
    </footer>
</template>
