import {createApp, App as Application} from 'vue';
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import {createPinia} from "pinia";
import '@/bootstrap.scss'
import 'bootstrap-icons/font/bootstrap-icons.scss'
import {registerServices} from "@/servicesProvider";
import {Api} from "@/services/api";
import VueGtag from "vue-gtag"

const app: Application = createApp(App);

// registerServices(app);

const api = new Api(process.env.VUE_APP_API_URL);

const pinia = createPinia();
pinia.use(() => ({api: api}));

app.provide('api', api);
app.use(router);
app.use(pinia);
app.use(
    VueGtag,
    {
        config: {
            id: process.env.VUE_APP_ANALYTICS_ID,
        }
    },
    router
);
app.mount('#app');
