import {defineStore} from "pinia";
import {User} from "@/services/api/entities";
import {Ref, ref} from "vue";
import {usePartStore} from "@/store/part";

const useAuthStore = defineStore('auth', function () {
    const isInitializedStore: Ref<Boolean> = ref(false);
    const userStore: Ref<User | null> = ref(null);
    const csrfStore: Ref<string | null> = ref('');

    const partStore = usePartStore();

    function authorize(user: User, csrf: string) {
        userStore.value = user;
        csrfStore.value = csrf;

        partStore.clear();
    }

    async function logout() {
        // @ts-ignore
        await this.api.logout()

        userStore.value = null;
        csrfStore.value = null;

        partStore.clear();
    }

    async function fetchUser(): Promise<User | null> {
        if (!userStore.value) {
            // @ts-ignore
            userStore.value = await this.api.getUser();
            isInitializedStore.value = true;

            return userStore.value;
        }

        return userStore.value;
    }

    async function reFetchUser() {
        // @ts-ignore
        userStore.value = await this.api.getUser();
    }

    return {
        user: userStore,
        csrf: csrfStore,
        isInitialized: isInitializedStore,
        authorize,
        logout,
        fetchUser,
        reFetchUser,
    };
});

export {useAuthStore}

